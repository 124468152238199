import React from 'react';

import Logo from '../../molecules/Logo/Logo';

import './Header.css';

const Header = () => (
  <header className="app-header">
    <section className="logo-panel">
      <Logo />
    </section>
  </header>
);

export default Header;
