import React from 'react';

import './Link.css';

export type LinkProps = {
  label: string;
  href: string;
};

const Link = ({ label, ...rest }: LinkProps) => (
  <a className="button button--plain button--primary" {...rest}>
    {label}
  </a>
);

export default Link;
