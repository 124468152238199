import Joi from 'joi';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import Axios from 'axios';

export type AppConfigProps = {
  children: ReactNode;
  envConfig: Record<string, string | boolean | undefined>;
};

export type ProcessEnvConfig = {
  NODE_ENV: 'development' | 'production';
  REACT_APP_DEFAULT_LANGUAGE: string;
  REACT_APP_PAYLIB_P2P_URL: string;
  REACT_APP_EXPENSES_GROUP_DYNAMIC_LINK: string;
};

export type BuildConfig = {
  isProd: boolean;
  locale: string;
  paylibP2PUrl: string;
};

export type RuntimeConfig = {
  expensesGroupDynamicLink: string;
  expensesGroupTabDynamicLink: string;
};

export type AppConfig = BuildConfig & RuntimeConfig;

export const AppConfigContext = createContext<AppConfig>({} as AppConfig);

const PROCESS_ENV_SCHEMA = Joi.object({
  REACT_APP_DEFAULT_LANGUAGE: Joi.string().default('fr'),
  NODE_ENV: Joi.string().default('development'),
  REACT_APP_PAYLIB_P2P_URL: Joi.string().required(),
});

async function loadRuntimeConfig(): Promise<RuntimeConfig> {
  const { status, data } = await Axios.get('/env');

  if (status >= 300 || !data) {
    throw new Error('Failed to fetch env file');
  }
  return data;
}

async function loadBuildConfig(config: ProcessEnvConfig): Promise<BuildConfig> {
  const processEnvConfig: ProcessEnvConfig = Joi.attempt(
    config,
    PROCESS_ENV_SCHEMA,
    {
      stripUnknown: true,
    },
  );

  return {
    isProd: processEnvConfig.NODE_ENV === 'production',
    locale: processEnvConfig.REACT_APP_DEFAULT_LANGUAGE,
    paylibP2PUrl: processEnvConfig.REACT_APP_PAYLIB_P2P_URL,
  };
}

const AppConfigProvider = ({ children, envConfig }: AppConfigProps) => {
  const [appConfig, setAppConfig] = useState<AppConfig>();

  async function fetchConfig() {
    const buildConfig = await loadBuildConfig(
      // eslint-disable-next-line @typescript-eslint/ban-types
      (envConfig as {}) as ProcessEnvConfig,
    );
    const runtimeConfig = await loadRuntimeConfig();
    setAppConfig({ ...buildConfig, ...runtimeConfig });
  }

  useEffect(() => {
    if (!appConfig) {
      fetchConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {appConfig && (
        <AppConfigContext.Provider value={appConfig}>
          {children}
        </AppConfigContext.Provider>
      )}
    </>
  );
};

export default AppConfigProvider;
