import { FormatXMLElementFn } from 'intl-messageformat';
import React, { ReactNode } from 'react';
import { CGU_PATH } from '../../app/app.routes';

export type IntlRichTextFormat = Record<string, FormatXMLElementFn<ReactNode>>;

export const APP_DEFAULT_LANGUAGE = 'fr';

const RICH_TEXT_FORMAT: IntlRichTextFormat = {
  newline: (chunks: ReactNode) => (
    <>
      <br />
      {chunks}
    </>
  ),
  highlight: (chunks: ReactNode) => (
    <span className={'highlight'}>{chunks}</span>
  ),
  p: (chunks: ReactNode) => <p>{chunks}</p>,
  strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
  phone: (chunks: ReactNode) => (
    <a href={`tel: ${chunks?.toString().replace(' ', '')}`}>{chunks}</a>
  ),
  mail: (chunks: ReactNode) => (
    <a href={`mailto: ${chunks?.toString()}`}>{chunks}</a>
  ),
  firstname: (chunks: ReactNode) => (
    <span className={'firstname'}>{chunks}</span>
  ),
  cgu: (chunks: ReactNode) => (
    <a href={CGU_PATH} target="_blank" rel="noreferrer">
      {chunks}
    </a>
  ),
};

export default RICH_TEXT_FORMAT;
