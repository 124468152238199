import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { IntlRichTextFormat } from '../shared/constants/intl';
import AppRouting, { AppRouteProps } from './AppRouting';
import { AppConfigContext } from '../shared/providers/AppConfigProvider';

export type AppProps = {
  defaultLocale: string;
  routes: AppRouteProps[];
  richTextFormat?: IntlRichTextFormat;
};

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'fr':
    default:
      return import('i18n/compiled-lang/fr.json');
  }
}

export const App = ({ defaultLocale, routes, richTextFormat }: AppProps) => {
  const { locale } = useContext(AppConfigContext);
  const [messages, setMessages] = useState<Record<string, string>>();

  useEffect(() => {
    const loadMessages = async () => {
      const i18nData = await loadLocaleData(locale);
      // eslint-disable-next-line @typescript-eslint/ban-types
      setMessages((i18nData as {}) as Record<string, string>);
    };
    loadMessages();
  }, [locale]);

  return (
    <div className="app">
      <IntlProvider
        defaultRichTextElements={richTextFormat}
        locale={locale}
        messages={messages}
        defaultLocale={defaultLocale}
      >
        <AppRouting routes={routes} />
      </IntlProvider>
    </div>
  );
};

export default App;
