import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../app.routes';
import { ExpensesGroupContext } from '../../../shared/providers/ExpensesGroupProvider';
import { AppConfigContext } from '../../../shared/providers/AppConfigProvider';

type ExpensesGroupRedirectParams = {
  groupId: string;
  tabName: string;
};

const ExpensesGroupRedirectPage = () => {
  const { groupId: groupIdParam, tabName: tabNameParam } = useParams<
    ExpensesGroupRedirectParams
  >();
  const { setGroupId, groupId, isGroupIdValid } = useContext(
    ExpensesGroupContext,
  );
  const { expensesGroupTabDynamicLink } = useContext(AppConfigContext);
  const history = useHistory();

  if (!groupIdParam) {
    history.push(NOT_FOUND_PATH);
  }

  useEffect(() => {
    if (groupIdParam) {
      setGroupId(groupIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIdParam]);

  useEffect(() => {
    if (
      (groupId && !isGroupIdValid) ||
      !['membres', 'depenses', 'regler'].includes(tabNameParam)
    ) {
      history.push(NOT_FOUND_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, tabNameParam]);

  if (isGroupIdValid) {
    window.location.replace(
      expensesGroupTabDynamicLink
        .replace('{GROUP_ID}', groupId)
        .replace('{TAB_NAME}', tabNameParam),
    );
    return null;
  }

  return <div>Loading...</div>;
};

export default ExpensesGroupRedirectPage;
