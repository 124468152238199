import React from 'react';

import cguHtml from './cgu.html';

import DefaultTemplate from '../../components/templates/DefaultTemplate/DefaultTemplate';

import './CguPage.css';

const CguPage = () => (
  <div className="cgu-page">
    <DefaultTemplate>
      <div dangerouslySetInnerHTML={{ __html: cguHtml }} />
    </DefaultTemplate>
  </div>
);

export default CguPage;
