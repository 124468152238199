import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isDesktop } from 'react-device-detect';
import DefaultTemplate from '../../components/templates/DefaultTemplate/DefaultTemplate';
import OTPMailLogo from '../../../assets/images/otp-mail.png';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right-arrow-icon.svg';

import './OTPMailNotFoundPage.css';

const renderDesktopTemplate = () => (
  <div className="otp-mail-not-found-container">
    <div className="text-container">
      <h1>
        <FormattedMessage
          id="otp.mail.not-found.desktop.title"
          defaultMessage="Avez-vous ouvert le mail sur <highlight>votre téléphone</highlight> ?"
        />
      </h1>
      <p className="subtitle">
        <FormattedMessage
          id="otp.mail.not-found.desktop.subtitle"
          defaultMessage="Si vous visualisez cette page, c’est que vous avez sans doute ouvert le lien sur votre ordinateur."
        />
      </p>
      <h2>
        <FormattedMessage
          id="otp.mail.not-found.desktop.action"
          defaultMessage="Veuillez cliquer sur le bouton ”Confirmer” affiché dans l'e-mail depuis votre téléphone."
        />
      </h2>
    </div>
    <div className="logo-container">
      <img src={OTPMailLogo} alt="OTP Mail example" className="logo" />
    </div>
  </div>
);

const renderMobileTemplate = () => (
  <div className="otp-mail-not-found-container">
    <h1>
      <FormattedMessage
        id="otp.mail.not-found.mobile.title"
        defaultMessage="Oups, vous ne devriez pas être ici !"
      />
    </h1>
    <p className="subtitle">
      <FormattedMessage
        id="otp.mail.not-found.mobile.subtitle"
        defaultMessage="Il semble que vous rencontrez une erreur lors de la redirection vers notre application."
      />
    </p>
    <h2>
      <FormattedMessage
        id="otp.mail.not-found.mobile.action"
        defaultMessage="Essayez tout d'abord de redémarrer votre téléphone puis de cliquer à nouveau sur le bouton ”Confirmer” affiché dans l'e-mail."
      />
    </h2>
    <a
      href="https://paylib102742.typeform.com/to/eczhNlnK"
      target="_blank"
      rel="noreferrer"
      className="form-container"
    >
      <p className="label">
        <FormattedMessage
          id="otp.mail.not-found.mobile.go-to-form"
          defaultMessage="J’ai redémarré mon téléphone mais je rencontre cette page malgré tout !"
        />
      </p>
      <RightArrowIcon className="arrow" />
    </a>
  </div>
);

export const OTPMailNotFoundPage = () => {
  return (
    <DefaultTemplate>
      {isDesktop ? renderDesktopTemplate() : renderMobileTemplate()}
    </DefaultTemplate>
  );
};

export default OTPMailNotFoundPage;
