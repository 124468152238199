import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import notFoundIllustration from 'images/error-illustration.svg';

import DeadEndTemplate from '../../components/templates/DeadEndTemplate/DeadEndTemplate';
import Link from '../../components/atoms/Link/Link';
import { AppConfigContext } from '../../../shared/providers/AppConfigProvider';

import './NotFoundPage.css';

const NotFoundPage = () => {
  const intl = useIntl();
  const { paylibP2PUrl } = useContext(AppConfigContext);

  return (
    <div className="not-found-page">
      <DeadEndTemplate
        illustration={notFoundIllustration}
        title={intl.formatMessage({
          id: 'notfound.title',
          defaultMessage:
            '<strong>Oups, </strong>la page que vous cherchez semble introuvable.',
        })}
      >
        <Link
          label={intl.formatMessage({
            id: 'notfound.button',
            defaultMessage: 'J’ai compris',
          })}
          href={paylibP2PUrl}
        />
      </DeadEndTemplate>
    </div>
  );
};

export default NotFoundPage;
