import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import APP_ROUTES from './app/app.routes';
import AppConfigProvider from './shared/providers/AppConfigProvider';
import APP_RICH_TEXT_FORMAT, {
  APP_DEFAULT_LANGUAGE,
} from './shared/constants/intl';
import ExpensesGroupProvider from './shared/providers/ExpensesGroupProvider';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <AppConfigProvider envConfig={process.env}>
      <ExpensesGroupProvider>
        <App
          routes={APP_ROUTES}
          defaultLocale={APP_DEFAULT_LANGUAGE}
          richTextFormat={APP_RICH_TEXT_FORMAT}
        />
      </ExpensesGroupProvider>
    </AppConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
