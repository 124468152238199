import React, { ReactNode } from 'react';

import Header from '../../organisms/Header/Header';

import './DeadEndTemplate.css';

export type DeadEndProps = {
  title?: string;
  illustration?: string;
  children?: ReactNode;
};

const DeadEndTemplate = ({ children, title, illustration }: DeadEndProps) => (
  <div className="flex-wrapper">
    <div className="dead-end-wrapper">
      <Header />
      <main>
        <img className="illustration" src={illustration} alt="" />
        <h2 className="title">{title}</h2>
        {children}
      </main>
    </div>
  </div>
);

export default DeadEndTemplate;
