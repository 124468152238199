import React, { createContext, ReactElement, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

export type ExpensesGroupContextProps = {
  groupId: string;
  setGroupId: (groupId: string) => void;
  isGroupIdValid: boolean;
};

export type ExpensesGroupProviderProps = {
  children: ReactElement | ReactElement[];
};

export const ExpensesGroupContext = createContext<ExpensesGroupContextProps>(
  {} as ExpensesGroupContextProps,
);

const ExpensesGroupProvider = ({ children }: ExpensesGroupProviderProps) => {
  const [groupId, setGroupId] = useState<string>('');
  const [isGroupIdValid, setIsGroupIdValid] = useState<boolean>(false);

  const isMobile = () => isIOS || isAndroid;

  const isGroupIdFormatValid = (value: string): boolean =>
    // eslint-disable-next-line max-len
    /^[_\-0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]+$/.test(
      value,
    ) && isMobile();

  const context = {
    groupId,
    setGroupId: (value: string) => {
      setGroupId(value);
      setIsGroupIdValid(isGroupIdFormatValid(value));
    },
    isGroupIdValid,
  };

  return (
    <ExpensesGroupContext.Provider value={context}>
      {children}
    </ExpensesGroupContext.Provider>
  );
};

export default ExpensesGroupProvider;
