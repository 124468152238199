import React, { useContext } from 'react';

import logo from 'images/logo-paylib.svg';

import { AppConfigContext } from '../../../../shared/providers/AppConfigProvider';

import './Logo.css';

const Logo = () => {
  const { paylibP2PUrl } = useContext(AppConfigContext);

  return (
    <a href={paylibP2PUrl} className="logo">
      <img src={logo} alt="Le logo de Paylib" />
    </a>
  );
};

export default Logo;
