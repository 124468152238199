import React, { ElementType } from 'react';
import {
  BrowserRouter,
  Route,
  RouteProps,
  Switch,
  Redirect,
} from 'react-router-dom';

import { NOT_FOUND_PATH } from './app.routes';

export type AppRouteProps = { component: ElementType } & Pick<
  RouteProps,
  'path'
>;

export type AppRoutingProps = {
  routes: AppRouteProps[];
};

const AppRouting = ({ routes }: AppRoutingProps) => (
  <BrowserRouter>
    <Switch>
      {routes.map((route: AppRouteProps, index: number) => (
        <Route
          exact
          key={index}
          path={route.path}
          render={(props) => <route.component {...props} />}
        />
      ))}
      <Route path="*">
        <Redirect to={NOT_FOUND_PATH} />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default AppRouting;
