import NotFoundPage from './containers/NotFoundPage/NotFoundPage';
import LegalPage from './containers/LegalPage/LegalPage';
import CguPage from './containers/CguPage/CguPage';
import PrivacyPoliciesPage from './containers/PrivacyPoliciesPage/PrivacyPoliciesPage';
import ExpensesGroupJoinPage from './containers/ExpensesGroupJoinPage/ExpensesGroupJoinPage';
import ExpensesGroupRedirectPage from './containers/ExpensesGroupRedirectPage/ExpensesGroupRedirectPage';
import OTPMailNotFoundPage from './containers/OTPMailNotFoundPage/OTPMailNotFoundPage';

export const NOT_FOUND_PATH = '/404';
export const LEGAL_PATH = '/mentions-legales';
export const PRIVACY_POLICIES_PATH =
  '/politique-de-protection-des-donnees-personnelles';
export const CGU_PATH = '/conditions-generales-utilisation';
export const EXPENSES_GROUP_JOIN_PATH = '/groupes/rejoindre';
export const EXPENSES_GROUP_REDIRECTION_PATH = '/groupe';
export const OTP_MAIL_NOT_FOUND_PATH = '/groupes/otp/email';

const APP_ROUTES = [
  {
    path: `${EXPENSES_GROUP_JOIN_PATH}/:groupId?`,
    component: ExpensesGroupJoinPage,
  },
  {
    path: `${EXPENSES_GROUP_REDIRECTION_PATH}/:groupId?/:tabName?`,
    component: ExpensesGroupRedirectPage,
  },
  {
    path: NOT_FOUND_PATH,
    component: NotFoundPage,
  },
  {
    path: LEGAL_PATH,
    component: LegalPage,
  },
  {
    path: CGU_PATH,
    component: CguPage,
  },
  {
    path: PRIVACY_POLICIES_PATH,
    component: PrivacyPoliciesPage,
  },
  {
    path: `${OTP_MAIL_NOT_FOUND_PATH}/:code?`,
    component: OTPMailNotFoundPage,
  },
];

export default APP_ROUTES;
