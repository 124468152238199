import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../app.routes';
import { ExpensesGroupContext } from '../../../shared/providers/ExpensesGroupProvider';
import { AppConfigContext } from '../../../shared/providers/AppConfigProvider';

type ExpensesGroupJoinParams = {
  groupId: string;
};

const ExpensesGroupJoinPage = () => {
  const { groupId: groupIdParam } = useParams<ExpensesGroupJoinParams>();
  const { setGroupId, groupId, isGroupIdValid } = useContext(
    ExpensesGroupContext,
  );
  const { expensesGroupDynamicLink } = useContext(AppConfigContext);
  const history = useHistory();

  if (!groupIdParam) {
    history.push(NOT_FOUND_PATH);
  }

  useEffect(() => {
    if (groupIdParam) {
      setGroupId(groupIdParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIdParam]);

  useEffect(() => {
    if (groupId && !isGroupIdValid) {
      history.push(NOT_FOUND_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  if (isGroupIdValid) {
    window.location.replace(expensesGroupDynamicLink.replace('{ID}', groupId));
    return null;
  }

  return <div>Loading...</div>;
};

export default ExpensesGroupJoinPage;
