import React from 'react';

import legalHtml from './legal.html';

import DefaultTemplate from '../../components/templates/DefaultTemplate/DefaultTemplate';

import './LegalPage.css';

const LegalPage = () => (
  <div className="legal-page">
    <DefaultTemplate>
      <div dangerouslySetInnerHTML={{ __html: legalHtml }} />
    </DefaultTemplate>
  </div>
);

export default LegalPage;
