import React from 'react';

import privacyPoliciesHtml from './privacy-policies.html';

import DefaultTemplate from '../../components/templates/DefaultTemplate/DefaultTemplate';

import './PrivacyPoliciesPage.css';

const PrivacyPoliciesPage = () => (
  <div className="privacy-policies-page">
    <DefaultTemplate>
      <div dangerouslySetInnerHTML={{ __html: privacyPoliciesHtml }} />
    </DefaultTemplate>
  </div>
);

export default PrivacyPoliciesPage;
