import React, { ReactNode } from 'react';
import Header from '../../organisms/Header/Header';

import './DefaultTemplate.css';

export type DefaultTemplateProps = {
  children?: ReactNode;
};

const DefaultTemplate = ({ children }: DefaultTemplateProps) => (
  <div className="wrapper">
    <Header />
    <main>{children}</main>
  </div>
);

export default DefaultTemplate;
